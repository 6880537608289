/*
 *	RECAPTCHA
 */

function recaptchaLoaded() {
	var forms = document.querySelectorAll('form.recaptchaform');
	var recaptchas = document.querySelectorAll('div[class=g-recaptcha]');

	// Loop & render badges
	for (i=0; i<recaptchas.length; i++) {
		(function(index) {
			grecaptcha.render(recaptchas[index].id, {
				'sitekey': recaptchas[index].dataset.sitekey,
				'badge': 'inline',
				'size': 'invisible',
				'callback': function(response) {
					forms[index].submit();
				}
			});
		})(i)
	}
	
	// Loop forms & catch submits
	for (i=0; i<forms.length; i++) {
		(function(index) {
			forms[index].addEventListener('submit', function(e) {
				// don't execute with errors on form
				if (!this.checkValidity || this.checkValidity()) {
					grecaptcha.execute(index);
				}
				
				e.preventDefault();
	
				return false;
			});
		})(i)
	}
}